export default function Footer() {
  return (
    <div className="art-ls-social">
      <a
        href="https://www.linkedin.com/in/KrishnaSharmaRS"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-linkedin-in" />
      </a>
      <a
        href="https://www.github.com/KrishnaSharmaRS"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-github" />
      </a>
      <a
        href="https://www.twitter.com/KrishnaSharmaRS"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-twitter" />
      </a>
      <a
        href="https://www.medium.com/@KrishnaSharmaRS"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-medium" />
      </a>
      <a
        href="https://www.dev.to/KrishnaSharmaRS"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fab fa-dev" />
      </a>
    </div>
  );
}
